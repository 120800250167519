<template>
    <div class="webinar-list">
        <template v-if="error">
            <div class="error" v-if="error" >
                <img src="./../assets/images/icon-error.png" alt="error" />
                <p v-html="error"></p>
            </div>
        </template>
        <template v-else>
            <h1>My Webinars</h1>
            <div class="webinar-container" v-if="webinar">
                <div class="webinar-item" v-for="(item, idx) in webinar" :key="idx">
                    <div class="row">
                        <div class="col2">
                            <h2>{{ item.name }} </h2>
                            <p v-html="item.topic"></p>
                            <h3>
                                The video of the webinar will be available and visible from<br />
                                {{ dateFormat(item.date_start).replace('à', '-') }}<br />
                                until<br />
                                {{ dateFormat(item.date_end).replace('à', '-')}} 
                            </h3>
                            
                        </div>
                        <div class="col-sep"></div>
                        <div class="col2">
                            <img :src="`${$store.state.URL}avatars/${item.speaker_avatar}`" alt="avatar" />
                            <p class="speaker-name">{{ `${item.speaker_title} ${item.firstname} ${item.lastname}` }}</p>
                            <p v-html="item.speaker_description"></p>
                        </div>
                    </div>
                    <div class="btn-container">
                        <template v-if=" item.webinar_user_state == 2">
                            <p class="date-msg" v-if="!item.start">This session is not opened yet</p>
                            <p class="date-msg" v-else-if="!item.end">This session is closed</p>
                            <router-link v-else :to="`/webinar/${item.webinar_id}`" class="btn-form">Enter</router-link>
                        </template>
                        <template  v-else-if="item.webinar_user_state == 1">
                            <div class="btn-form disabled">Pending validation</div>
                        </template>
                    </div>
                </div>
                <div v-if="webinar.length == 0">
                    <div class="no-webinar" >
                        <p>You haven't registered yet for our webinars.</p>
                        <p>You will be informed about our new webinars by following us on LinkedIn.</p>
                    </div>
                </div>
            </div>
        </template>

    </div>
</template>

<script>
import axios from 'axios'
import store from './../store'

export default {
    name : 'WebinarList',
    data() {
        return {
            webinar : null,
            error : null
        }
    },
    mounted() {
        let vm = this
        this.error = null

        axios
            .get(store.getters.getURL + "webinars/getWebinarUser.php?id="+this.$store.state.AUTH.user_id+"&token="+store.state.JWT_TOKEN )
            .then(function (response) { 
                if(response.data.state == 'success') {
                    vm.webinar = response.data.res
                }else {
                    if(response.data.authError) {
                        store.commit('logout')
                        vm.$router.push('/login')
                    }else {
                        vm.error = response.data.retour
                    }
                }
            })
            .catch((err) => {
                this.errorTxt = 'An error has occured: ' +  err 
            });
    },
    methods : {
        dateFormat(item) {
            const date = new Date(item);
            const formattedDate = date.toLocaleString("fr-Fr", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit"
            });

            return formattedDate;
        }
    }
}
</script>

<style lang="scss" scoped>

.webinar-list {
    width:1450px;
    max-width: 95%;
    margin:0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 80px;
}

.webinar-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.webinar-item {
    margin-top:40px;
    padding: 40px;
    background:#e6e6e6;
}

.webinar-item .row {
    display: flex;
    align-items: center;
}

.webinar-item .row .col2:last-child {
    border-left:1px solid $secondaryColor;
}

.webinar-item .row .col2 {
    padding:0 5%;
}

.webinar-item h2 {
    text-align: left;
    color:$mainColor;
    font-size:22px;
    line-height: 25px;
    font-family:$fontBold;   
    margin-bottom: 0;
}

.webinar-item h3 {
    text-align: left;
    color:$mainColor;
    font-size:18px;
    font-family:$font;   
    margin-bottom: 20px;
    margin-top: 40px;
    line-height: 25px;
}

.webinar-item p {
    text-align: left;
    font-size:15px;
    color:$secondaryColor;
}

.webinar-item img {
    width:75px;
    height:75px;
    margin: 0 auto 20px auto;
    border-radius:50%;
}

.speaker-name {
    font-family: $fontBold;
}

.btn-container {
    display: flex;
    justify-content: center;
    margin-top:40px;
}

.btn-form {
    text-decoration: none;
    margin:0 10px;
}

.btn-form.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.no-webinar {
    margin-top:15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-webinar p {
    color:$mainColor;
    margin-top:40px;
    font-size: 25px;
    font-family: $fontBold;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .webinar-item .row .col2:last-child {
        border-left:none;
        border-top: 1px solid $secondaryColor;
        padding-top:40px;
        margin-top: 40px;
    }

}
</style>